export const logIn = (data) => {
  return {
    type: "LOGIN",
    data: data,
  };
};

export const logOut = (data) => {
  return {
    type: "LOGOUT",
    data: data,
  };
};
